import { Modal } from 'antd';
import type { PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { ImgFormatter, MainSubStructureModel, TimeStampFormatter } from 'egenie-utils';
import React from 'react';
import type { MainItem } from './types';
import type { Store } from './index';

export function mainSubStructureModel(context: Store): MainSubStructureModel {
  return new MainSubStructureModel({
    pageId: '60098',
    grid: {
      columns: [
        {
          key: 'pic',
          name: '图片',
          width: 80,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 200,
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
        },
        {
          name: '货主',
          key: 'ownerName',
          width: 200,
        },
        {
          name: '供应商',
          key: 'vendorName',
          width: 200,
        },
        {
          name: '唯一码',
          key: 'uniqueCode',
          width: 200,
        },
        {
          name: '创建时间',
          key: 'createTime',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.createTime}/>,
        },
        {
          name: '质检问题',
          key: 'defectiveItem',
          width: 150,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/qualityDefectiveGoodsInformation/index',
    },
    buttons: [
      {
        permissionId: '6',
        text: '导出',
        icon: 'icon-export',
        handleClick: () => {
          const ids = Array.from(context.mainSubStructureModel.gridModel.selectedIds).join(',');
          const fileName = '质检次品';
          const exportType = 'unique_code_quality_inspection';
          const queryParam = context.normalProgramme.filterItems.params;
          const queryParamShow = context.normalProgramme.filterItems.translateParams.join(' ');

          if (ids) {
            context.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
          } else {
            Modal.confirm({
              title: '提示',
              content: '未选择数据将导出全部数据?',
              onOk: () => {
                context.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
              },
            });
          }
        },
      },
    ],
    api: {
      onQuery: async(params) => {
        const {
          filterParams = {},
          ...rest
        } = params;

        await context.normalProgramme.filterItems.validator();
        return request<PaginationData<MainItem>>({
          url: '/api/cloud/wms/rest/quality/cloud/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    hiddenSubTable: true,
  });
}
