import { Card, Layout } from 'antd';
import type { DictData } from 'egenie-common';
import { objToDict } from 'egenie-common';
import type { NormalProgramme, MainSubStructureModel, BaseData } from 'egenie-utils';
import { ExportModal, request, MainSubStructure, NormalProgrammeComponent, ExportStore } from 'egenie-utils';
import React from 'react';
import { getOwner } from '../../utils';
import styles from './index.less';
import { mainSubStructureModel } from './mainSubStructureModel';
import { normalProgramme } from './normalProgramme';

export class Store {
  constructor() {
    this.mainSubStructureModel = mainSubStructureModel(this);

    this.normalProgramme = normalProgramme(this);

    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;

    request<BaseData<Record<string, DictData>>>({ url: '/api/cloud/wms/rest/quality/cloud/queryUniqueItem' })
      .then((info) => {
        const defectiveItem = [];
        Object.values(info.data)
          .forEach((item: DictData) => defectiveItem.push(...objToDict(item)));
        this.normalProgramme.filterItems.addDict({ defectiveItem });
      });

    getOwner()
      .then((ownerIds) => this.normalProgramme.filterItems.addDict({ ownerIds }));
  }

  public mainSubStructureModel: MainSubStructureModel;

  public exportStore: ExportStore = new ExportStore({ parent: null });

  public normalProgramme: NormalProgramme;
}

const store = new Store();

export default function() {
  return (
    <>
      <Layout className={styles.container}>
        <Layout.Content>
          <Card size="small">
            <NormalProgrammeComponent store={store.normalProgramme}/>
          </Card>
          <div className={styles.tableWrapper}>
            <MainSubStructure store={store.mainSubStructureModel}/>
          </div>
        </Layout.Content>
      </Layout>
      <ExportModal store={store.exportStore}/>
    </>
  );
}
