import { NormalProgramme } from 'egenie-utils';
import type { Store } from './index';

export function normalProgramme(context: Store): NormalProgramme {
  return new NormalProgramme({
    filterItems: [
      {
        type: 'dateRange',
        field: 'createTime',
        label: '日期',
      },
      {
        type: 'select',
        field: 'ownerIds',
        label: '货主',
        mode: 'multiple',
      },
      {
        type: 'input',
        field: 'uniqueCode',
        label: '唯一码',
      },
      {
        type: 'input',
        field: 'skuNo',
        label: 'SKU编码',
      },
      {
        type: 'select',
        field: 'defectiveItem',
        label: '质检问题',
        mode: 'multiple',
      },
    ],
    count: 6,
    handleSearch: () => context.mainSubStructureModel.onQuery(),
  });
}
